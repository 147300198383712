import React, { useContext, useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { ContextLoader } from '../context'
import loadFonts from '../helpers/loadFonts'
import { GlobalEventBus } from '../helpers/eventBus.js'
import { MOBILE_BP, ratio, ratioM } from '../helpers/sizes'

const Loader = ({ pathname, data }) => {

	const [isLoading, updateIsLoading] = useContext(ContextLoader)
	const [isImageVisible, setIsImageVisible] = useState(false)
	const imageEl = useRef(null)

	// General loading handler
	useEffect(() => {
		// Show image
		requestAnimationFrame(() => {
			setIsImageVisible(true)
		})
		// First load fonts
		handleFontLoad().then(() => {
			setTimeout(() => {
				updateIsLoading(false)
			}, data.duration * 1000)
		})
	}, [updateIsLoading, pathname])

	// Listen for home canvas textures loaded
	useEffect(() => {
	}, [])

	// Load fonts
	const handleFontLoad = () => {
		return new Promise((resolve, reject) => {
			loadFonts().then(() => {
				resolve()
			});
		})
	}

	useEffect(() => {
		let imageWidthInVw = null
		if (localStorage.getItem('is-touch') === 'true') {
			imageWidthInVw = ratioM(data.image_width_mobile) + 'vw'
		} else {
			imageWidthInVw = ratio(data.image_width) + 'vw'
		}
		imageEl.current.style.width = imageWidthInVw
	}, [])

	return (
		<>
			<div
				className={`Loader ${!isLoading ? 'Loader--Ended' : ''}`}
				style={{ backgroundColor: `${data.background}` }}
			>
				<img
					className={`Loader__Image ${isImageVisible ? 'Loader__Image--Visible' : ''}`}
					alt={data.image.alt}
					src={data.image.fluid.src}
					srcSet={data.image.fluid.srcSet}
					// style={{ width: imageWidth + 'vw' }}
					ref={imageEl}
				/>
			</div>
		</>
	)
}

export default Loader

export const query = graphql`
	fragment Loader on PrismicLoader {
		data {
			image {
				alt
				fluid {
					src
					srcSet
				}
			}
			image_width
			image_width_mobile,
			background,
			duration
		}
	}
`