// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

export const initGa = () => {
	ReactGA.initialize('G-NQ7PBQY9Y8')
	ReactGA.send("pageview")
}

export const handlePageView = location => {
	ReactGA.send({ hitType: "pageview", page: `/${location.pathname + location.search}` })
}