/**
*	Those variables and functions are the sames defined in SCSS
*/

const COL_WIDTH_IN_VW = 6.9565217391 // 100*(96/1380)
const GUTTER_INNER_WIDTH_IN_VW = 1.3768115942 // 100*(19/1380)
const GUTTER_OUTER_WIDTH_IN_VW = 0.7246376812 // 100*(10/1380)
const BASE_SIZE = 100 * (10 / 1380)

const COL_MOBILE_WIDTH_IN_VW = 14.4927536232 // 100*(60/414)
const GUTTER_INNER_MOBILE_WIDTH_IN_VW = 1.9323671498 // 100*(8/414)
const GUTTER_OUTER_MOBILE_WIDTH_IN_VW = 1.690821256 // 100*(7/414)
const BASE_SIZE_MOBILE = 100 * (10 / 414)

const MOBILE_BP = 820 // same as css

const cols = (colsNumber = 1, guttersNumber = colsNumber > 0 ? colsNumber - 1 : 0) => {
	return (COL_WIDTH_IN_VW * colsNumber) + (GUTTER_INNER_WIDTH_IN_VW * guttersNumber)
}

const colsWithOuterGutter = (colsNumber = 1, guttersNumber = colsNumber > 0 ? colsNumber - 1 : 0) => {
	return (COL_WIDTH_IN_VW * colsNumber) + (GUTTER_INNER_WIDTH_IN_VW * guttersNumber) + GUTTER_OUTER_WIDTH_IN_VW
}

const colsM = (colsNumber = 1, guttersNumber = colsNumber > 0 ? colsNumber - 1 : 0) => {
	return (COL_MOBILE_WIDTH_IN_VW * colsNumber) + (GUTTER_INNER_MOBILE_WIDTH_IN_VW * guttersNumber)
}

const ratio = (multiplier = 1) => {
	return (BASE_SIZE * (multiplier * 0.1))
}

const ratioM = (multiplier = 1) => {
	return (BASE_SIZE_MOBILE * (multiplier * 0.1))
}

export {
	MOBILE_BP,
	COL_WIDTH_IN_VW,
	GUTTER_INNER_WIDTH_IN_VW,
	GUTTER_OUTER_WIDTH_IN_VW,
	COL_MOBILE_WIDTH_IN_VW,
	GUTTER_INNER_MOBILE_WIDTH_IN_VW,
	GUTTER_OUTER_MOBILE_WIDTH_IN_VW,
	cols,
	colsWithOuterGutter,
	colsM,
	ratio,
	ratioM
}