import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { ContextLoader, ContextProjectIndex } from '../context'
import Loader from './Loader.js'
import Transition from './Transition.js'
import '../helpers/noSSR.js'
import './../styles/styles.scss'
import { GlobalEventBus } from '../helpers/eventBus'
import { initGa, handlePageView } from '../services/GoogleAnalytics'

const Layout = ({ children, location, data }) => {

	const [isLoading, updateIsLoading] = useState(true)
	const [projectIndex, updateProjectIndex] = useState(0)

	useEffect(() => {
		document.body.classList.toggle('hasOverflowHidden', location.pathname === '/')
	}, [location.pathname])

	const handleLogoClick = () => {
		if (location.pathname === '/') {
			GlobalEventBus.emit('onHomeLeave', {
				dir: 'left'
			})
			navigate('/about')
		} else {
			navigate('/')
		}
	}

	/*
	*	Google Analytics
	*/
	useEffect(() => {
		initGa()
	}, [])

	useEffect(() => {
		handlePageView(location)
	}, [location])

	let currentPageName = ''
	if (typeof window !== `undefined`) {
		currentPageName = location.pathname.replaceAll('/', '')
		currentPageName = currentPageName === '404' ? 'notfound' : currentPageName
	}

	return (
		<>
			<ContextLoader.Provider value={[isLoading, updateIsLoading]}>
			<ContextProjectIndex.Provider value={[projectIndex, updateProjectIndex]}>
				<div className={`WeLoveNoise ${currentPageName}`}>
					<Loader pathname={location.pathname} data={data.prismicLoader.data} />
					<div onClick={handleLogoClick}>
						<svg className="Logo" viewBox="0 0 26 67" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M11.305.68v44.894L.926 15.106v28.898h3.39v-8.432l10.38 30.469V21.145l10.378 30.47v-28.9h-3.39v8.433L11.305.68Z"/></svg>
					</div>
					<Transition location={location}>
						<div className="App__TransitionContainer">
							{children}
						</div>
					</Transition>
				</div>
			</ContextProjectIndex.Provider>
			</ContextLoader.Provider>
		</>
	)
}

export default Layout